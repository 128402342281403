<template>
  <div class="row m-1 px-1">
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-stack" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Fetch Inventory Summary
              </h4>
              <p class="card-text mb-0">
                Inventory Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="my-0">
                  To get the inventory summary by store ID, please enter the <b class="text-warning"> STORE ID </b> and click the button below.
                </p>
                <p class="my-0">
                  If you enter 0 as the store ID, it will get the inventory summary for all stores.
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-12 col-sm-6">
                <label for="storeID">Store ID</label>
                <dx-util-number-box id="storeID" v-model="storeID" styling-mode="filled">
                  <dx-util-validator>
                    <dx-util-required-rule message="Store ID is required" />
                  </dx-util-validator>
                </dx-util-number-box>
              </div>
              <div class="col-12 col-sm-6 pl-sm-1">
                <label>&nbsp;</label>
                <dx-util-button text="Fetch Inventory Summary" type="default" styling-mode="contained" :element-attr="btnElementAttr" @click="getInventorySummary" />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-stack" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Clean Inventory
              </h4>
              <p class="card-text mb-0">
                Inventory Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-md-12 mb-1">
                <p class="my-0">
                  To clean the inventory by account number, please enter the <b class="text-warning">ACCOUNT NO </b> and click the button below.
                </p>
                <p class="my-0">
                  If you enter 0 as the store ID, it will clean the inventory for all stores.
                </p>
                <p class="my-0">
                  This function is only for <b class="text-warning">DPC managed accounts.</b>
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-12 col-sm-6">
                <label for="accountNo">Account No</label>
                <dx-util-number-box id="accountNo" v-model="accountNo" styling-mode="filled">
                  <dx-util-validator>
                    <dx-util-required-rule message="Store ID is required" />
                  </dx-util-validator>
                </dx-util-number-box>
              </div>
              <div class="col-12 col-sm-6 pl-sm-1">
                <label>&nbsp;</label>
                <dx-util-button text="Clean Inventory" type="default" styling-mode="contained" :element-attr="btnElementAttr" @click="cleanInventory" />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maintenanceService from '@/http/requests/common/maintenanceService'
import { Notify } from '@robustshell/utils/index'

export default {
  data() {
    return {
      storeID: null,
      accountNo: null,
      btnElementAttr: {
        class: 'btn-block',
      },
    }
  },
  methods: {
    getInventorySummary(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        maintenanceService
          .fetchInventorySummaryForStore(this.storeID)
          .then(() => {
            Notify.success('Fetch Inventory Summary job is created successfully.')
          })
          .catch(error => {
            Notify.error(`Job creation failed.${error}`)
          })
      }
    },
    cleanInventory(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        maintenanceService
          .cleanInventory(this.accountNo)
          .then(() => {
            Notify.success('Clean inventory job is created successfully.')
          })
          .catch(error => {
            Notify.error(`Job creation failed.${error}`)
          })
      }
    },
  },
}
</script>
